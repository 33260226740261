import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useStaticQueryGetNoteCategory } from "services/graphQl/queries/careNotesCategories"
import { useStaticQueryGetCareNotesCategorList } from "services/graphQl/queries/careNotesList"
import HELPERS from "utils/helpers"
import UnOrderList from "components/blocks/unOrderList"
import CategoryCard from "components/blocks/categoryCard"
import "./learn.scss"

const GetStaffMemberSlugPrefix = () => {
  const query = useStaticQuery(
    graphql`
      query NoteSlugPrefix {
        template: contentfulWebPageTemplate(model: { eq: "Care Note" }) {
          slugPrefix
        }
      }
    `
  )
  return query.template.slugPrefix
}

const LearnPage = () => {
  const careNotesCategories = useStaticQueryGetNoteCategory()
  const careNotesList = useStaticQueryGetCareNotesCategorList().map(note => ({
    ...note,
    slug: `/${GetStaffMemberSlugPrefix()}/${note.slug}`,
  }))
  const [selectedCategoryList, setSelectedCategoryList] =
    useState(careNotesList)

  const handleClick = e => {
    if (e !== "All") {
      setSelectedCategoryList(
        careNotesList?.filter(item => item.category?.id === e)
      )
    } else {
      setSelectedCategoryList(careNotesList)
    }
  }

  useEffect(() => {
    const params = HELPERS.getUrlParameters(window.location.search)

    if (params.category) {
      setSelectedCategoryList(
        careNotesList?.filter(item => item.category?.id === params.category)
      )
    }
  }, [careNotesList])

  return (
    <div id="Learn">
      <div className="Categories-wrapper">
        <div className="container">
          <div className="row display-flex justify-content-center">
            <div className="col-12">
              <UnOrderList
                data={careNotesCategories}
                handleClick={e => handleClick(e)}
              />
            </div>
          </div>
        </div>
      </div>
      <div id="content">
        <div className="container">
          <div className="row">
            {selectedCategoryList?.map(card => (
              <div className="col-md-4 my-3" key={card?.id}>
                <CategoryCard {...card} categoryKey="learn" />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default LearnPage
