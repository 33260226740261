import React, { useState } from "react"
import { useMediaPredicate } from "react-media-hook"
import ModalVideo from "react-modal-video"
import slugify from "slugify"
import scrollTo from "gatsby-plugin-smoothscroll"
import CallToAction from "components/blocks/callToAction"
import Button from "components/elements/button"
import Image from "components/elements/image"
import Video from "components/elements/video"
import { HeroVideoBaseProps } from "./props"
import "./heroVideo.scss"

const MainHeroVideo = ({
  actions,
  backgroundVideo,
  description,
  navigator,
  subtitle,
  title,
}) => {
  const [toggler, setToggler] = useState(false)
  const [videoId, setVideoId] = useState(null)
  const isMobile = useMediaPredicate("(max-width: 768px)")
  const handleClick = item => {
    if (item?.action?.__typename === "ContentfulActionOpenVideo") {
      setToggler(!toggler)
      setVideoId(item?.action?.videoWrapper?.url.split("vimeo.com/").pop())
    }
  }

  return (
    <>
      <div className="main p-4 p-md-5 text-white" id="HeroSection">
        <Video
          src={backgroundVideo?.file?.url}
          style={{
            bottom: 0,
            height: "100%",
            left: 0,
            objectFit: "cover",
            objectPosition: "center",
            position: "absolute",
            right: 0,
            top: 0,
            width: "100%",
            zIndex: "-1",
          }}
        />
        <div className="container-fluid h-100">
          <div className="content p-5">
            {title && <h1 className="headline mb-1">{title}</h1>}
            {subtitle && <h1 className="headline mb-3"> {subtitle}</h1>}
            {description && (
              <p className="sub-headline">{description.description}</p>
            )}
            {actions?.map((item, index) => (
              <CallToAction
                ctaWrapper="d-flex justify-content-start align-items-center"
                data={item}
                handleClick={() => handleClick(item)}
                key={index}
                type="button"
              />
            ))}
          </div>
          {!isMobile && navigator && (
            <div className="footer w-100  h-100 d-flex flex-direction-column  justify-content-end align-items-center">
              <Image
                alt="Scroll down to learn more"
                className="scrollDown mb-2"
                image={navigator?.buttonImage?.fluid}
                width={17}
              />
              <Button
                className="text"
                handleClick={() =>
                  scrollTo(`#${slugify(navigator.action?.section?.title)}`)
                }
              >
                {navigator.buttonText}
              </Button>
            </div>
          )}
        </div>
      </div>
      <ModalVideo
        autoPlay
        channel="vimeo"
        isOpen={toggler}
        onClose={() => setToggler(false)}
        videoId={videoId}
      />
    </>
  )
}

MainHeroVideo.propTypes = HeroVideoBaseProps

export default MainHeroVideo
