import React, { useState } from "react"
import PropTypes from "prop-types"
import Iframe from "react-iframe"
import Loader from "react-loader-spinner"
import "./iframe.scss"

const IFrame = ({ iframeLink }) => {
  const [loading, setLoading] = useState(true)

  const hideSpinner = () => {
    setLoading(false)
  }

  return (
    <div id="IFrame">
      {loading && (
        <Loader color="#7A4D8B" height={100} type="TailSpin" width={100} />
      )}
      <Iframe className="iframe" onLoad={hideSpinner} url={iframeLink} />
    </div>
  )
}

export const IFrameProps = {
  iframeLink: PropTypes.string,
}

IFrame.propTypes = IFrameProps

export default IFrame
