import React from "react"
import PropTypes from "prop-types"
import slugify from "slugify"
import { CORTICA_CAREERS_IFRAME } from "utils/constants"
import LocationsPage from "views/locations"
import LearnPage from "views/learn"
import TreatmentPage from "components/sections/treatment"
import IFrame from "components/elements/iframe"
import DepartmentsList from "components/sections/departmentsList"
import LeadershipStaffMembers from "components/sections/leadership"
// import Testimonials from "components/sections/testimonials"
import "./dynamicSection.scss"

const DynamicSection = ({ data }) => {
  switch (slugify(data.dynamicSection)) {
    // Note: Removes old Testimonials from homepage for now.
    // case "Testimonials":
    //   return <Testimonials {...data} />

    // Note: This component doesn't take any props
    case "Care-Notes":
      return <LearnPage />

    // Note: The usage here actually doesn't even have the necessary data to work properly
    // Missing: headline, treatments
    case "Treatments":
      return <TreatmentPage {...data} />

    // Note: The usage here actually doesn't even have the necessary data to work properly
    // Missing: members
    case "Staff-Members":
      return <LeadershipStaffMembers {...data} />

    case "Clinics":
      return <LocationsPage {...data} />

    case "Careers-Details":
      return <IFrame iframeLink={CORTICA_CAREERS_IFRAME} />

    case "Departments":
      return <DepartmentsList {...data} />

    default:
      return null
  }
}

export const DynamicSectionProps = {
  additionalSettings: PropTypes.shape({
    enableDirectionsCTA: PropTypes.bool,
    testimonialsCount: PropTypes.number,
  }),
  additionalText: PropTypes.shape({
    json: PropTypes.object,
  }),
  backgroundColor: PropTypes.string,
  dynamicSection: PropTypes.string,
  sections: PropTypes.string,
  style: PropTypes.string,
  title: PropTypes.string,
  web_page: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string,
      title: PropTypes.string,
    })
  ),
}

DynamicSection.propTypes = {
  data: PropTypes.shape(DynamicSectionProps),
}

export default DynamicSection
