import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import _ from "lodash"
import { useStaticQuery, graphql } from "gatsby"
import { geocodeByLatLng } from "react-google-places-autocomplete"
import { getDistance, convertDistance } from "geolib"
import currentLocationIcon from "images/currentLocation_icon.svg"
import { useStaticQueryGetClinicsAddresses } from "services/graphQl/queries/clinicsAddresses"
import { googleMapURL } from "utils/constants"
import RichText from "components/elements/richText"
import PlacesAutocomplete from "components/elements/placesAutocomplete"
import Map from "components/elements/map"
import AddressCard from "components/blocks/addressCard"
import "./locations.scss"

const GetClinicSlugPrefix = () => {
  const query = useStaticQuery(
    graphql`
      query ClinicSlugPrefix {
        template: contentfulWebPageTemplate(model: { eq: "Clinic" }) {
          slugPrefix
        }
      }
    `
  )
  return query.template.slugPrefix
}

const LocationsPage = ({ additionalSettings, additionalText }) => {
  const allClinics = _.sortBy(useStaticQueryGetClinicsAddresses(), ["name"])
  const [clinics, setClinics] = useState(allClinics)
  const [closestCenter, setClosestCenter] = useState("")
  const [place, setPlace] = useState("")
  const [mapCenter, setMapCenter] = useState(null)
  const [mapZoom, setMapZoom] = useState(null)
  const [closeButton, setCloseButton] = useState(false)

  useEffect(() => {
    if (clinics.length === 1) {
      setMapCenter({
        lat: clinics[0].address.lat,
        lng: clinics[0].address.lon,
      })
      setMapZoom(9)
    } else {
      setMapCenter(null)
      setMapZoom(null)
    }
  }, [clinics])

  const handleChange = geoCoor => {
    if (geoCoor === null) {
      restoreClinics()
      return
    }

    let minimumClinic = []
    let minimum = 200

    for (let i = 0; i < allClinics.length; i++) {
      const distance = convertDistance(
        getDistance(
          { latitude: geoCoor.lat, longitude: geoCoor.lng },
          {
            latitude: allClinics[i].address.lat,
            longitude: allClinics[i].address.lon,
          }
        ),
        "mi"
      )

      if (distance <= minimum) {
        minimumClinic = [allClinics[i]]
        minimum = distance
      }
    }

    setClinics(minimumClinic)
    setCloseButton(true)
    setClosestCenter(Math.round(minimum))
  }

  const handleZoomChange = zoom => {
    setMapZoom(zoom)
  }

  const handleMarkerClick = locationIndex => {
    setClinics([allClinics[locationIndex]])
    setClosestCenter("")
    setPlace("")
    setCloseButton(false)
  }

  const restoreClinics = () => {
    setClinics(allClinics)
    setClosestCenter("")
    setPlace("")
    setCloseButton(false)
  }

  const getCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition(function (position) {
      const geoCoor = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      }

      geocodeByLatLng(geoCoor)
        .then(results => setPlace(results[0].formatted_address))
        .catch(error => console.error(error))

      handleChange(geoCoor)
    })
  }

  const renderClinics = () => {
    return (
      <>
        {clinics.length < allClinics.length && closestCenter && (
          <div className="closest-center-wrapper">
            <h4>Closest Center</h4>
            <p>({closestCenter} miles)</p>
          </div>
        )}

        <div className="row">
          {clinics.map((clinic, index) => {
            const address = {
              ...clinic,
              slug: `/${GetClinicSlugPrefix()}/${clinic.slug}`,
            }
            return (
              <div className="col-lg-4 col-md-6 col-sm-12" key={index}>
                <AddressCard
                  {...address}
                  enableDirectionsCTA={additionalSettings?.enableDirectionsCTA}
                />
              </div>
            )
          })}
        </div>
      </>
    )
  }

  const markerPositions = allClinics.map(item => {
    item.address.showInfo = false
    item.address.info = item.name
    return item.address
  })

  return (
    <div className="Locations">
      <div id="content">
        <div className="container">
          <div className="locations-wrapper">
            <div className="locations-head-wrapper">
              <h3 className="head-title">Find a Cortica Center Near You</h3>
              <div className="head-right-side">
                <div className="input-icon-wrapper">
                  <PlacesAutocomplete
                    closeButton={closeButton}
                    defaultValue={place}
                    googleMapURL={googleMapURL}
                    loadingElement={<div style={{ height: "100%" }} />}
                    placeChanged={handleChange}
                    setValue={setPlace}
                  />
                </div>
                <button
                  className="head-current-location"
                  onClick={getCurrentLocation}
                >
                  <span className="head-current-location-title">
                    Use Current Location
                  </span>
                  <img alt="Current Location" src={currentLocationIcon} />
                </button>
              </div>
            </div>
            <Map
              containerElement={
                <div style={{ height: "400px", marginBottom: "30px" }} />
              }
              googleMapURL={googleMapURL}
              handleMarkerClick={handleMarkerClick}
              isMarkerShown
              loadingElement={<div style={{ height: "100%" }} />}
              mapCenter={mapCenter}
              mapElement={<div style={{ height: "100%" }} />}
              mapZoom={mapZoom}
              markerPosition={markerPositions}
              zoomChange={handleZoomChange}
            />
            {clinics.length > 0
              ? renderClinics()
              : additionalText && (
                  <RichText richText={additionalText} textStyle="full" />
                )}
            {clinics.length < allClinics.length && (
              <div className="see-all-locations-wrapper">
                <button
                  className="see-all-locations-btn"
                  onClick={restoreClinics}
                >
                  See All Locations
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export const LocationsPageProps = {
  additionalSettings: PropTypes.shape({
    enableDirectionsCTA: PropTypes.bool,
    testimonialsCount: PropTypes.number,
  }),
  additionalText: PropTypes.shape({
    json: PropTypes.object,
  }),
}

LocationsPage.propTypes = LocationsPageProps

export default LocationsPage
