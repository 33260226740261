import React from "react"
import PropTypes from "prop-types"
import { withPrefix } from "gatsby"

const Video = ({ autoPlay, poster, src, style }) => (
  <video
    autoPlay={autoPlay}
    muted
    poster={poster}
    preload="auto"
    src={withPrefix(src)}
    style={style}
    volume="5"
  >
    <source src={withPrefix(src)} type="video/mp4" />
    Your device does not support playing &apos;video/mp4&apos; videos
  </video>
)

Video.propTypes = {
  autoPlay: PropTypes.bool,
  poster: PropTypes.string,
  src: PropTypes.string,
  style: PropTypes.object,
}

export default Video
