import React from "react"
import PropTypes from "prop-types"
import TreatmentPage, {
  TreatmentPageProps,
} from "components/sections/treatment"
import LeadershipStaffMembers, {
  LeadershipStaffMembersProps,
} from "components/sections/leadership"

const OrderedList = ({ headlineOrdered, items, title, type }) => {
  switch (type) {
    case "Treatments":
      return (
        <TreatmentPage
          headline={
            headlineOrdered?.headlineOrdered
              ? headlineOrdered.headlineOrdered
              : title
              ? title
              : null
          }
          treatments={items}
        />
      )

    case "Staff Members":
      return <LeadershipStaffMembers members={items} />

    default:
      return null
  }
}

export const OrderedListProps = {
  headlineOrdered: PropTypes.shape({
    headlineOrdered: PropTypes.string,
  }),
  items: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape(TreatmentPageProps),
      PropTypes.shape(LeadershipStaffMembersProps),
    ])
  ),
  title: PropTypes.string,
  type: PropTypes.string,
}

OrderedList.propTypes = OrderedListProps

export default OrderedList
