import React from "react"
import PropTypes from "prop-types"
import comp from "images/comprehensive.png"
import evidence from "images/evidence.png"
import personal from "images/personalizedCare.png"
import research from "images/research.png"
import "./referralPageDetails.scss"

const specialties = [
  "Autism Spectrum Disorder",
  "ADHD",
  "Epilepsy",
  "Genetic Syndromes",
  "Sensory Processing Disorders",
  "and much more!",
]
const features = [
  {
    img: comp,
    text: (
      <>
        Our comprehensive care includes every service a neurodivergent child
        might need, all in one place.
      </>
    ),
  },
  {
    img: personal,
    text: <>Our care plans are personalized to each child we serve.</>,
  },
  {
    img: evidence,
    text: (
      <>
        Our{" "}
        <a
          href="https://www.corticacare.com/care-notes/comprehensive-personalized-medically-based-care-model-improves-adaptive-behavior-in-autism"
          rel="noreferrer"
          target="_blank"
        >
          evidence-based therapies
        </a>{" "}
        lead to significantly greater improvement in Vineland-3 scores.
      </>
    ),
  },
  {
    img: research,
    text: (
      <>
        We are advancing the field with groundbreaking research in therapy and
        medical care. All patients have the opportunity to participate in{" "}
        <a
          href="https://www.corticacare.com/cortica-innovation-network"
          rel="noreferrer"
          target="_blank"
        >
          ongoing clinical research
        </a>
        .
      </>
    ),
  },
]

const Specialty = ({ children }) => <li>{children}</li>

const Feature = ({ img, text }) => (
  <div className="feature">
    <img alt="Feature icon" src={img} />
    <p>{text}</p>
  </div>
)

const ReferralPageDetails = () => {
  return (
    <div className="details-container">
      <div className="columns">
        <div className="left-column">
          <div className="header">
            <h2>Why is Cortica the best choice for my patients?</h2>
          </div>
          <div className="features-grid">
            {features.map((feature, index) => (
              <Feature img={feature.img} key={index} text={feature.text} />
            ))}
          </div>
        </div>
        <div className="right-column">
          <div className="header">
            <h2>What we specialize in:</h2>
            <p>
              Cortica specializes in providing comprehensive and individualized
              care for individuals with neurodevelopmental differences,
              including:
            </p>
          </div>
          <ul>
            {specialties.map((specialty, index) => (
              <Specialty key={index}>{specialty}</Specialty>
            ))}
          </ul>
          <div className="refer-button-container">
            <button
              className="refer-btn-yellow"
              onClick={() => (window.location.href = "#Form")}
            >
              Refer a patient
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
Feature.propTypes = {
  img: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}
Specialty.propTypes = {
  children: PropTypes.node.isRequired,
}
export default ReferralPageDetails
