import { useStaticQuery, graphql } from "gatsby"

export const useStaticQueryGetClinicsAddresses = () => {
  const query = useStaticQuery(
    graphql`
      query ClinicsAddresses {
        allContentfulClinic {
          nodes {
            detailedAddress
            fax
            id
            name
            phone
            slug
            address {
              lat
              lon
            }
          }
        }
      }
    `
  )
  return query.allContentfulClinic.nodes
}
