import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import CategoryCard, { CategoryCardProps } from "components/blocks/categoryCard"
import "./treatment.scss"

const GetTreatmentsSlugPrefix = () => {
  const query = useStaticQuery(
    graphql`
      query SlugPrefix {
        template: contentfulWebPageTemplate(model: { eq: "Treatment" }) {
          slugPrefix
        }
      }
    `
  )
  return query.template.slugPrefix
}

const TreatmentPage = ({ headline, treatments }) => {
  const treatmentList = treatments.map(treatment => ({
    ...treatment,
    slug: `/${GetTreatmentsSlugPrefix()}/${treatment.slug}`,
  }))

  return (
    <div className="explore-section">
      <div className="container">
        {headline && <h2 className="headline">{headline}</h2>}
        <div className="row justify-content-center">
          {treatmentList.map((card, index) => (
            <div
              className={`col-md-6 d-flex ${
                !(index % 2) ? "justify-content-end" : "justify-content-start"
              }`}
              key={index}
            >
              <CategoryCard
                {...card}
                backGround={card.style}
                categoryKey="treatment"
                ctaText={card.cta}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export const TreatmentPageProps = {
  headline: PropTypes.string,
  treatments: PropTypes.arrayOf(PropTypes.shape(CategoryCardProps)),
}

TreatmentPage.propTypes = TreatmentPageProps

export default TreatmentPage
