import React from "react"
import PropTypes from "prop-types"
import { navigate } from "gatsby"
// import PATHS from "static/constants/paths"
import Link from "components/elements/link"
import "./addressCard.scss"

const AddressCard = ({
  address: { lat, lon },
  detailedAddress,
  enableDirectionsCTA,
  fax,
  name,
  phone,
  slug,
}) => (
  <div className="mb-5" id="locationCard">
    <div className="container">
      {name && (
        <Link className="link headline" to={slug}>
          {name}
        </Link>
      )}

      {detailedAddress && (
        <div className="address-wrapper">
          <p className="m-0 views-lable">Address:</p>
          <p
            className="address-info text text-left p-0 m-h d-flex align-items-start"
            title={detailedAddress}
          >
            {detailedAddress.length > 80
              ? `${detailedAddress.slice(0, 80)}...`
              : detailedAddress}
          </p>
        </div>
      )}

      <div className="links-wrapper">
        {enableDirectionsCTA && (
          <>
            <Link
              className="link custome d-inline-block"
              to={`https://www.google.com/maps/search/?api=1&query=${lat},${lon}`}
            >
              Get Directions
            </Link>
            <br />
          </>
        )}
      </div>

      {phone && (
        <div className="phone mb-4">
          <div className="views-lable">Phone:</div>
          <a className="link custome d-inline-block" href={`tel:${phone}`}>
            {phone}
          </a>
        </div>
      )}
      {fax && (
        <div className="fax">
          <div className="views-lable">Fax:</div>
          <p className="custome">{fax}</p>
        </div>
      )}
      <button
        className="primaryButton shadow py-2 px-3"
        onClick={() => navigate(slug)}
      >
        Details/Hours
      </button>
    </div>
  </div>
)

export const AddressCardProps = {
  address: PropTypes.shape({
    lat: PropTypes.number,
    lon: PropTypes.number,
  }),
  detailedAddress: PropTypes.string,
  fax: PropTypes.string,
  name: PropTypes.string,
  phone: PropTypes.string,
  slug: PropTypes.string,
}

AddressCard.propTypes = {
  ...AddressCardProps,
  enableDirectionsCTA: PropTypes.bool,
}

export default AddressCard
