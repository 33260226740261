import React from "react"
import PropTypes from "prop-types"
import "./unOrderList.scss"

const UnOrderList = ({ data = [], handleClick, listStyle = "none" }) => (
  <ul id="unOrderList">
    <li
      onClick={() => handleClick("All")}
      onKeyDown={() => handleClick("All")}
      role="presentation"
      style={{ listStyle: listStyle }}
    >
      All
    </li>
    {data?.map((item, i) => (
      <li
        key={i}
        onClick={() => handleClick(item.id)}
        onKeyDown={() => handleClick(item.id)}
        role="presentation"
        style={{ listStyle: listStyle }}
      >
        {item.name}
      </li>
    ))}
  </ul>
)

UnOrderList.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  handleClick: PropTypes.func,
  listStyle: PropTypes.string,
}

export default UnOrderList
