import { useStaticQuery, graphql } from "gatsby"

export const useStaticQueryGetNoteCategory = () => {
  const query = useStaticQuery(
    graphql`
      query NoteCategory {
        allContentfulNoteCategory {
          nodes {
            id
            name
            theme
          }
        }
      }
    `
  )
  return query.allContentfulNoteCategory.nodes
}
