import React from "react"
import { withScriptjs } from "react-google-maps"
import { geocodeByPlaceId } from "react-google-places-autocomplete"
import Autocomplete from "react-google-autocomplete"
import CloseIcon from "images/clearCurrentLocation_icon.svg"
import "./placesAutocomplete.scss"

const PlacesAutocomplete = withScriptjs(props => {
  const placeChanged = params => {
    if (params.name === "") {
      props.placeChanged(null)
      return
    }

    geocodeByPlaceId(params.place_id).then(
      response => {
        const geoCoor = {
          lat: response[0].geometry.location.lat(),
          lng: response[0].geometry.location.lng(),
        }
        props.placeChanged(geoCoor)
      },
      error => {
        console.error(error)
      }
    )
  }

  const placeFocused = () => {
    props.setValue(null)
  }

  const closeButtonClicked = () => {
    props.placeChanged(null)
  }

  return (
    <div className="google-places-autocomplete-wrapper">
      <Autocomplete
        className="auto-complete"
        defaultValue={props.defaultValue}
        googleMapsScriptBaseUrl="https://maps.googleapis.com/maps/api/place/autocomplete/"
        onFocus={placeFocused}
        onPlaceSelected={placeChanged}
        options={{
          types: ["geocode", "establishment"],
        }}
        placeholder="Enter Your Address"
      />
      {props.closeButton && (
        <img
          alt="Close Button Icon"
          className="close-icon"
          onClick={closeButtonClicked}
          onKeyDown={closeButtonClicked}
          role="presentation"
          src={CloseIcon}
        />
      )}
    </div>
  )
})

export default PlacesAutocomplete
