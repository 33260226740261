import React from "react"
import PropTypes from "prop-types"
import slugify from "slugify"
import MainHeroVideo from "./mainHeroVideo"
import InnerPagesHeroPages from "./innerPagesHeroPages"
import { HeroVideoBaseProps } from "./props"

const HeroVideo = ({ data }) => {
  if (data.style) {
    switch (slugify(data.style)) {
      case "Full-Background":
        return <MainHeroVideo {...data} />

      case "Gradient-Black-Centered":
        return <InnerPagesHeroPages {...data} />

      default:
        return null
    }
  } else return null
}

export const HeroVideoProps = HeroVideoBaseProps

HeroVideo.propTypes = {
  data: PropTypes.shape(HeroVideoProps),
}

export default HeroVideo
