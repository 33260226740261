import React from "react"
import PropTypes from "prop-types"
import CallToAction, { CallToActionProps } from "components/blocks/callToAction"
import "./form.scss"

const Form = ({ action }) => (
  <form
    action={action.action.url}
    autoComplete="off"
    id="downloadBrochure"
    method="post"
  >
    {action.action.label && (
      <label className="d-none" htmlFor="Your-email" id="email">
        {action.action.label}
      </label>
    )}
    <input
      id="Your-email"
      name="EMAIL"
      placeholder="Email Address"
      required
      type="email"
    />
    <CallToAction
      btnType="submit"
      className="subscriptionFormAction"
      data={action}
      type="button"
    />
  </form>
)

export const FormProps = {
  action: PropTypes.shape(CallToActionProps),
}

Form.propTypes = FormProps

export default Form
