import PropTypes from "prop-types"
import { CallToActionProps } from "components/blocks/callToAction"

export const HeroVideoBaseProps = {
  actions: PropTypes.arrayOf(PropTypes.shape(CallToActionProps)),
  backgroundColor: PropTypes.string,
  backgroundImage: PropTypes.shape({
    file: PropTypes.shape({
      url: PropTypes.string,
    }),
    fluid: PropTypes.shape({
      src: PropTypes.string,
    }),
    title: PropTypes.string,
  }),
  backgroundVideo: PropTypes.shape({
    file: PropTypes.shape({
      url: PropTypes.string,
    }),
    fluid: PropTypes.shape({
      src: PropTypes.string,
    }),
    title: PropTypes.string,
  }),
  description: PropTypes.shape({
    description: PropTypes.string,
  }),
  navigator: PropTypes.shape(CallToActionProps),
  photo: PropTypes.shape({
    file: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),
  style: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  video: PropTypes.shape({
    title: PropTypes.string,
    url: PropTypes.string,
  }),
  web_page: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string,
      title: PropTypes.string,
    })
  ),
}
