import React from "react"
import { HeroVideoBaseProps } from "./props"

const InnerPagesHeroPages = ({
  backgroundColor,
  description,
  subtitle,
  title,
}) => {
  return (
    <>
      <div
        className="video-bg-wrapper main"
        style={{ backgroundColor: backgroundColor }}
      >
        <div className="video-box">
          <div className="video-box-wrapper">
            {
              <div className="content">
                {title && <h1 className="headline">{title}</h1>}
                {subtitle && <h3 className="sub-headline">{subtitle}</h3>}
                {description && (
                  <div className="data">{description?.description}</div>
                )}
              </div>
            }
          </div>
        </div>
      </div>
    </>
  )
}

InnerPagesHeroPages.propTypes = HeroVideoBaseProps

export default InnerPagesHeroPages
