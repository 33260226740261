import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import slugify from "slugify"
import PATHS from "static/constants/paths.js"
import Link from "components/elements/link"
import Card from "components/sections/sectionList/card"
import "./departments.scss"

const DepartmentsList = ({ backgroundColor }) => {
  const [departments, setDepartments] = useState([])

  useEffect(() => {
    fetch("https://boards-api.greenhouse.io/v1/boards/cortica/departments")
      .then(res => res.json())
      .then(result => {
        setDepartments(result.departments)
      })
  }, [])

  return (
    <div className="career careers-bg" style={{ backgroundColor }}>
      <div className="container">
        <div className="headline-wrapper">
          <div className="header">Explore Jobs by Department:</div>
          <div className="sub-header">
            We are growing fast, really fast. Here are the latest openings, but
            there are certainly more to come:
          </div>
        </div>
        <div className="row">
          {departments
            ?.filter(dep => dep.jobs?.length)
            .map((item, index) => (
              <div className="col-md-6 col-12" key={index}>
                <Link
                  to={`${PATHS.DEPARTMENTS}/${slugify(item.name, {
                    lower: true,
                  })}-jobs`}
                >
                  <Card
                    content={item.name}
                    index={index}
                    subContent={`${item.jobs?.length} Job Openings`}
                  />
                </Link>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}

export const DepartmentsListProps = {
  backgroundColor: PropTypes.string,
}

DepartmentsList.propTypes = DepartmentsListProps

export default DepartmentsList
