import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import DetailedSection, {
  DetailedSectionProps,
} from "components/blocks/detailedSection"
import "./leadership.scss"

const GetMemberSlugPrefix = () => {
  const query = useStaticQuery(
    graphql`
      query MemberSlugPrefix {
        template: contentfulWebPageTemplate(model: { eq: "Staff Member" }) {
          slugPrefix
        }
      }
    `
  )
  return query.template.slugPrefix
}

const LeadershipStaffMembers = ({ members }) => {
  const staffMembers = members.map(item => ({
    ...item,
    slug: `/${GetMemberSlugPrefix()}/${item.slug}`,
  }))

  return (
    <div id="Leadership">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-9 col-12">
            <div className="content">
              <div className="row">
                {staffMembers.map((item, index) => (
                  <div className="col-md-4 col-12" key={index}>
                    <DetailedSection {...item} clickable />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const LeadershipStaffMembersProps = DetailedSectionProps

LeadershipStaffMembers.propTypes = {
  members: PropTypes.arrayOf(PropTypes.shape(DetailedSectionProps)),
}

export default LeadershipStaffMembers
