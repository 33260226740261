import React from "react"
import PropTypes from "prop-types"
import Image from "components/elements/image"
import Link from "components/elements/link"
import "./detailedSection.scss"

const DetailedSection = ({
  clickable,
  name,
  picture,
  role,
  slug,
  title,
  type,
}) => (
  <div className={clickable ? "clickable" : ""} id="detailedSection">
    {picture &&
      (clickable ? (
        <Link to={slug}>
          <div className="avatar">
            {type?.type === "link" ? (
              <Image image={picture.fluid} title={role} />
            ) : (
              <img alt={name} src={picture.fluid.src} />
            )}
          </div>
        </Link>
      ) : (
        <div className="avatar">
          {type?.type === "link" ? (
            <Image image={picture.fluid} title={role} />
          ) : (
            <img alt={name} src={picture.fluid.src} />
          )}
        </div>
      ))}
    {name && (
      <h2 className="title">
        {type?.type === "link" ? (
          <Link className="title" to={type?.route}>
            {title}
          </Link>
        ) : (
          name
        )}
      </h2>
    )}
    {role && <p className="subHeading">{role}</p>}
  </div>
)

export const DetailedSectionProps = {
  department: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  picture: PropTypes.shape({
    fluid: PropTypes.shape({
      src: PropTypes.string,
    }),
    title: PropTypes.string,
  }),
  role: PropTypes.string,
  slug: PropTypes.string,
}

DetailedSection.propTypes = {
  ...DetailedSectionProps,
  clickable: PropTypes.bool,
  title: PropTypes.string,
  type: PropTypes.string,
}

export default DetailedSection
