import React from "react"
import PropTypes from "prop-types"
import BackgroundImage from "gatsby-background-image"
import CallToAction, { CallToActionProps } from "components/blocks/callToAction"
import Form from "./form"
import "./heroImage.scss"

const HeroImage = ({
  action,
  backgroundImage,
  buttonText,
  description,
  headlineColor,
  headlineHero,
  style,
}) => {
  switch (style) {
    case "Brochure": {
      const backgroundFluidImageStack = [
        backgroundImage.fluid,
        `-webkit-linear-gradient(0deg,rgba(255,255,255,0.55) 34%,rgba(255,255,255,0) 67%)`,
      ].reverse()

      return (
        <BackgroundImage
          fluid={backgroundFluidImageStack}
          id="HeroImage"
          style={{
            backgroundPosition: "0px 0px,100% 0px,50% 50%",
            backgroundRepeat: "repeat,no-repeat,no-repeat",
            backgroundSize: "auto,cover,cover",
            zIndex: "1",
          }}
        >
          <div id="DownloadBrochureSection">
            <div className="container">
              <div className="content">
                {headlineHero && (
                  <div className="headline" style={{ color: headlineColor }}>
                    {headlineHero.headlineHero}
                  </div>
                )}
                {description && (
                  <div className="desc">{description.description}</div>
                )}
                {action?.style === "Form Action" && (
                  <Form action={action} buttonText={buttonText} />
                )}
              </div>
            </div>
          </div>
        </BackgroundImage>
      )
    }

    case "Centered Text":
      return (
        <BackgroundImage
          fluid={backgroundImage.fluid}
          id="HeroImage"
          style={{
            backgroundPositionY: "0",
            height: "600px",
            marginTop: "-26px",
            paddingTop: "20px",
            zIndex: "2",
          }}
        >
          <div id="SearchSection">
            <div className="container">
              <div className="content">
                {headlineHero && (
                  <div className="headline" style={{ color: headlineColor }}>
                    {headlineHero.headlineHero.split("\n").map((text, i) => (
                      <span key={i}>
                        {text}
                        <br />
                      </span>
                    ))}
                  </div>
                )}
                {action && <CallToAction data={action} />}
              </div>
            </div>
          </div>
        </BackgroundImage>
      )

    default:
      return null
  }
}

export const HeroImageProps = {
  action: PropTypes.shape(CallToActionProps),
  backgroundImage: PropTypes.shape({
    fluid: PropTypes.shape({
      src: PropTypes.string,
    }),
    title: PropTypes.string,
  }),
  buttonText: PropTypes.string,
  description: PropTypes.shape({
    description: PropTypes.string,
  }),
  headlineColor: PropTypes.string,
  headlineHero: PropTypes.shape({
    headlineHero: PropTypes.string,
  }),
  style: PropTypes.string,
}

HeroImage.propTypes = HeroImageProps

export default HeroImage
